<template>
  <div class="customer">
      <div class="tabWrap">
          <ul>
              <li v-for="(v,i) in timelist" :key=i :class="selectTime==i?'active':''" @click="choiceTheTime(i)">
                  {{v}}
              </li>
          </ul>
      </div>
      <div class="trapezoidWrap">
         <div class="raedAmount">
             <p class="name">
                 阅读量
             </p>
             <p class="num">
                 {{num_read}}
             </p>
         </div>
           <div class="forwardAmount">
             <p class="name">
                 转发量
             </p>
             <p class="num">
                 {{num_share}}
             </p>
         </div>
      </div>
      <div class="getGuest">
          <img :src="getGuestimg" alt="">
          <p class="name">
              获客统计
          </p>
      </div>
      <div class="list">
          <van-list
           :immediate-check="false"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            >
                <ul>
                    <li class="clearfix" v-for="(v,i) in list" :key="i">
                        <div class="imgWrap fl">
                            <van-image
                            width='1rem'
                            height='1rem'
                            fit="cover"
                            :src="v.user_avatar?v.user_avatar:defaultImg"
                            />
                            <span>{{v.count}}</span>
                        </div>
                        <div class="info fl">
                            <div class="line1 clearfix">
                                <div class="fl"> <span class="name mr fl hidden1">{{v.nick_name?v.nick_name:v.nick_name}}</span> <span class="copy fr"  v-clipboard:copy="v.nick_name"   v-clipboard:success="onCopy" >复制昵称</span></div> <div class="time fr">{{(v.visit_time+"").substring(0,10)}}</div>
                            </div>
                            <p class="line2">
                                <span>阅读了</span>《{{v.name}}》
                            </p>
                        </div>
                    </li>
                </ul>
        </van-list>

      </div>
      <tabbar :data='tabbarlist' :Jump='jump' :index="1"></tabbar>
  </div>
</template>

<script>
import tabbar from '@/components/tabbar/tabbar'
import api from "@/api/api"
export default {
    data(){
        return{
            finished:false,loading:false,page:0,
            selectTime:0,openid:"oftPL6r-CX6keK8gnFwADpmwiAls",data:"",num_read:0,num_share:0,time:1,
            list:[],
            timelist:[
                "今天", "昨天", "本月", "上月",
            ],
            defaultImg:require('../../img/my/avatar.png'),
            trapezoidImg:require('@/img/customer/yueduliang.png'),getGuestimg:require('@/img/customer/Icon_huoketongji.png'),
            tabbarlist:[
                {
                name:"首页",img:require("@/img/tabbar/Icon_Home_default.png")
                },
                {
                name:"客户",img:require("@/img/tabbar/Icon_Heart_selected.png")
                },
                {
                name:"我的",img:require("@/img/tabbar/Icon_Profile_default.png")
                }
            ],
        }
    },
    methods:{
        onLoad(){
            this.page+=1
            this.getdata(this.time,this.page)
        },
        jump(i){
        if(i==0){
            this.$router.push('/')
        }else if(i==2){
            this.$router.push('/my')
        }
        },
        onCopy(){
            this.$toast("复制成功")
        },
        choiceTheTime(i){
            this.page = 1
            this.list = []
            if(i!=this.selectTime){
                this.selectTime=i
                if(this.selectTime>=2){
                    this.time=i+2
                }else{
                    this.time=i+1
                }
            }
            this.getdata(this.time,this.page)
        },
        getdata(time,page){
            var that = this
            api.customer.getdata(this.openid,time,page).then(res => {
                if(res.data.code == 0){
                    console.log(res)
                    var data=res.data
                    that.list=that.list.concat(data.list.data)
                    that.num_read=data.num_read
                    that.num_share=data.num_share
                    that.loading = false;
                    that.finished = false;
                    if(res.data.list.data.length<10){
                        that.finished = true
                    }
                }else{
                     that.finished = true
                }
            })
        }
    },
    components:{
        tabbar
    },
    created(){
       this.openid = this.$store.state.openid
       this.onLoad()
    }
}
</script>

<style lang="less">
    .customer{
        width: 100%;
        .tabWrap{
            width: 100%;
            padding-top:.45rem;
            margin-bottom: .6rem;
            ul{
                display: flex;
                justify-content: space-around;
                li{
                    width: 1.52rem;
                    line-height: .6rem;
                    border-radius:.84rem;
                    color:#000;
                    font-size:.24rem;
                    text-align: center;
                    border:.02rem solid #f1f1f1;
                }
                .active{
                    background-color:#E45252;
                    color:#fff;
                }
            }
        }
        .trapezoidWrap{
            width: 100%;
            height:3rem;
            background-image: url('../../img/customer/yueduliang.png');
            background-repeat: no-repeat;
            background-size: 60%;
            background-position: center;
            color:#fff;
            font-size:.28rem;
            line-height:.4rem;
            margin-bottom:.6rem;
            .raedAmount{
                padding-top: .3rem;
                text-align: center;
            }
            .forwardAmount{
                padding-top: .7rem;
                text-align: center;
            }
        }
        .getGuest{
            background-color:rgba(255,134,92,.1);
            padding: .2rem;
            text-align: center;
            box-sizing: border-box;
            img{
                width:.48rem;
            }
            .name{
                font-size:.26rem;
                color:#FF865C;
            }
        }
        .list{
            width: 100%;
            padding:.3rem 0 1rem 0;
            ul{
                width: 100%;
                li{
                    width: 100%;
                    padding:.36rem .64rem;
                    box-sizing: border-box;
                    position: relative;
                    border-bottom:.01rem solid #F4F4F4;
                    .imgWrap{
                        position: relative;
                        width: 1rem;
                        margin-right:.38rem;
                        img{
                            border-radius:50%;
                        }
                        span{
                            position: absolute;
                            right: 0;
                            top:.8rem;
                            width: .4rem;
                            background-color:#E45252;
                            color:#fff;
                            font-size:.26rem;
                            border-radius: 50%;
                            text-align: center;
                            height: .4rem;
                            box-sizing: border-box;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    .info{
                        width: 4.8rem;
                        line-height:.4rem;
                        .line1{
                            margin-bottom:.24rem;
                            .name{
                                font-size:.32rem;
                                color:#5C666A;
                                height: .44rem;
                                max-width: 2rem;
                            }
                            .copy{
                                color:#E45252;
                                padding:.0 .1rem;
                                font-size:.22rem;
                                height: .4rem;
                                border-radius:.04rem;
                                border:.02rem solid #E45252;
                            }
                            .time{
                                font-size:.24rem;
                                color:#D7D7D7;
                            }
                        }
                        .line2{
                            font-size:.28rem;
                            color:#3B4E54;
                            font-weight: 600;
                            span{
                                color:#BAB9B9;
                            }
                        }
                    }
                }
            }
        }
    }
</style>