<template>
   <div class="tabbar">
            <ul>
                <li v-for="(v,i) in data" :key="i" @click="Jump(i)">
                    <img :src="v.img" alt="" class="imgMiffle">
                    <p class="name" :class="index==i?'active':''">{{v.name}}</p>
                </li>
            </ul>
        </div>
</template>

<script>
export default {
    props:['data','Jump','index']
}
</script>

<style lang="less">
        .tabbar{
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1.08rem;
            background-color: #fff;
            ul{
                display: flex;
                align-items: center;
                justify-content: space-around;
                li{
                    text-align:center;
                    img{
                        height: .56rem;
                    }
                    .name{
                        font-size:.24rem;
                        color:#4A4950;
                    }
                    .active{
                        color:#E45252;
                    }
                }
            }
        }
</style>